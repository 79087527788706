import * as React from "react"
import * as styles from './Header.module.scss'
const Header = () => {
  return (
    <header>
      <div className={styles.logo} alt='Half Moon Bouldering'></div>
      <div className={styles.motto}>Family Owned, Community Focused</div>
    </header>
  );
}
export default Header;