import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from 'gatsby'

const Head = ({pageTitle}) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title, description
        }
      }
    }  
  `);
  const title = `${pageTitle ? pageTitle + ' | ' : ''}${data.site.siteMetadata.title}`;
  return (
  <Helmet htmlAttributes={{ lang: 'en'}}>
    <title>{title}</title>
    <meta name="description"      content={data.site.siteMetadata.description} />
    <link rel="canonical"         content="https://halfmoonbouldering.com/" />
    <link rel="preconnect"        href="https://fonts.googleapis.com"/>
    <link rel="preconnect"        href="https://fonts.gstatic.com" crossOrigin='true'/>
    <link rel="stylesheet"        href="https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;700;900&display=swap"></link>
    <link rel="apple-touch-icon"  href="images/logo-72.png" />
    <meta rel="image_src"         href="http://halfmoonbouldering.com/images/logo-125.png" />
    <meta property="og:url"               content="https://halfmoonbouldering.com" />
    <meta property="og:type"              content="website" />
    <meta property="og:title"             content="Half Moon Bouldering, Seattle, Climbing Gym" />
    <meta property="og:description"       content={data.site.siteMetadata.description} />
    <meta property="og:image:secure_url"  content="https://halfmoonbouldering.com/images/logo-125.png" />
    <meta property="og:image"             content="http://halfmoonbouldering.com/images/logo-125.png" />
    <meta property="og:image:width"       content="150" />
    <meta property="og:image:height"      content="150" />
    <meta itemprop="thumbnailUrl"         content="http://halfmoonbouldering.com/images/logo-125.png" />
  </Helmet>
)};

export default Head;