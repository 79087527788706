import * as React from "react"
import Head from '../Head/Head';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Menu from '../Menu/Menu';
import './Layout.scss';

const Layout = ({pageTitle, children}) => {
  return (
    <>
      <Head pageTitle={pageTitle}/>
      <Header/>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-KDNVFH6XJP"></script>
      <Menu/>
      <main>
        {children}
      </main>
      <Footer/>
    </>
  )
}

export default Layout;
