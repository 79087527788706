import React, {useEffect, useRef} from "react"
import * as styles from './Footer.module.scss'
import mailSvg from "../../images/icon-mail.svg";
import fbSvg from "../../images/icon-fb.svg";
import igSvg from "../../images/icon-ig.svg";
import '../../styles/columnBreakpoints.scss';

const Footer = () => {
  const stickyClass = `${styles.footer} ${styles.sticky}`
  const bottomClass = `${styles.footer}`
  const footerRef = useRef();
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
  })

  const handleScroll = () => {
    if(typeof document == 'undefined') return;
    footerRef.current.className = 
      document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight - 20 ? 
        bottomClass : stickyClass;
  }

  return (
    <footer className={stickyClass} ref={footerRef}>
      <div className='column'>
        <div className={styles.bottomMenu}>
          <a href='/jobs'>Jobs</a>|
          {/* <a href='https://youtu.be/bOXceke1VvA?t=7' target='_blank' rel="noreferrer">Orientation</a>| */}
          <a href='https://waiver.smartwaiver.com/w/5ff5f4c266858/web' className={styles.waiver} target='_blank' rel="noreferrer">Waiver</a>|
          <a href='/my-membership' target='_blank' rel="noreferrer">My Membership</a>|
          <a href='/cancellation-reschedule-policy'>Cancelations</a>
        </div>
        <div className={styles.icons}>
          <a href="mailto:contact@halfmoonbouldering.com" target='_blank' rel="noreferrer">&nbsp;<img alt='email' src={mailSvg} title='contact@halfmoonbouldering.com'/></a>
          <a href='https://www.facebook.com/halfmoonbouldering' target='_blank' rel="noreferrer">&nbsp;<img alt='facebook' src={fbSvg}/></a>
          <a href='https://www.instagram.com/halfmoonbouldering' target='_blank' rel="noreferrer">&nbsp;<img alt='instagram' src={igSvg}/></a>
        </div>
      </div>
      <div className='column'>
        <div className={`${styles.copy}`}>© 2023 Half Moon Bouldering</div>
        <div className={styles.address}>
          <div><a href='https://goo.gl/maps/imxGoE3ksSL4DHAn7' target='_blank'>124 N 85th St, Seattle</a></div>
          <div>206.736.4596</div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;